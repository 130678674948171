<!-- @format -->

<template>
    <span>
        {{ $t('labels.agreeText1') }}
        <a
            href="https://help.ticketmaster.com/hc/en-us/articles/21729046347921-TM-Fanatics-Purchase-Experience-Terms-of-Use"
            target="_blank"
            @click="onClickLinkDo">
            {{ $t('_common:terms.termsOfUse') }}
        </a>
        {{ $t('labels.agreeText2') }}
        <a
            href="https://privacy.ticketmaster.com/privacy-policy"
            target="_blank"
            @click="onClickLinkDo">
            {{ $t('_common:terms.privacyPolicy') }}
        </a>
        {{ $t('labels.agreeText3') }}
        <a
            href="https://www.fanatics.com/privacy-policy/ch-35589354+z-9459912-4006290235"
            target="_blank"
            @click="onClickLinkDo">
            {{ $t('_common:terms.privacyPolicy') }}
        </a>
    </span>
</template>
<script>
export default {
    name: 'Step2DeliveryFormTermsLabel',
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.cart.theCheckoutWizard._components.step2Delivery.form',
    },
    methods: {
        onClickLinkDo(e) {
            e.stopPropagation();
        },
    },
};
</script>
