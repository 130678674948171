<template>
    <v-app-bar
        app
        flat
        class="gst-header">
        <BurgerMenu
            v-if="!$vuetify.breakpoint.mdAndUp"
            @expand="menuExpanded=true"
            @collapse="menuExpanded=false" />
        <TenantLogo />
        <v-fade-transition v-if="$vuetify.breakpoint.mdAndUp">
            <SearchKeywordWithAutocomplete
                v-if="withSearch || isSearchFocused"
                v-model="keyword"
                redirect-to-search-on-enter
                class="ml-4 mr-0 gst-header__search-keyword-with-autocomplete"
                :style="{'minWidth':'310px'}"
                @focus="onFocusSearchDo"
                @blur="onBlurSearchDo" />
        </v-fade-transition>
        <v-spacer />
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <MainMenu v-if="showMainMenu" />
            <LoginButtonVariant1 v-if="!userIsAuth" :data-test-id="$testId('login')" @click="goToLogin" />
            <UserDropdownMenu v-if="userIsAuth && userDetail" :user-detail="userDetail" />
        </template>
        <template v-else>
            <LoginButtonVariant1 v-if="!userIsAuth" :data-test-id="$testId('login')" @click="goToLogin" />
            <UserMobileMenu v-if="userIsAuth && userDetail"
                :user-detail="userDetail"
                @expand="userMenuExpanded=true"
                @collapse="userMenuExpanded=false" />
        </template>
    </v-app-bar>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import BurgerMenu from '@core/shared/components/menu/BurgerMenu.vue';
    import MainMenu from '@core/shared/components/menu/MainMenu.vue';
    import LoginButtonVariant1 from '@core/shared/components/authentication/LoginButtonVariant1.vue';
    import SearchKeywordWithAutocomplete from '@core/shared/components/search/SearchKeywordWithAutocomplete.vue';
    import UserDropdownMenu from '@core/shared/components/menu/UserDropdownMenu';
    import UserMobileMenu from '@core/shared/components/menu/UserMobileMenu';
    import { toggleBodyScroll as documentUtilsToggleBodyScroll } from '@core/utils/documentUtils';

    export default {
        name: 'TheHeader',
        components: {
            BurgerMenu,
            MainMenu,
            LoginButtonVariant1,
            SearchKeywordWithAutocomplete,
            UserDropdownMenu,
            UserMobileMenu
        },
        testIdOptions: {
            keyPrefix: 'layout.default.theHeader'
        },
        props: {
            withSearch: {
                type: Boolean,
                default: true
            },
            showMainMenu: {
                type: Boolean,
                default: true
            }
        },
        data: () => ( {
            menuExpanded: false,
            userMenuExpanded: false,
            isSearchFocused: false
        } ),
        computed: {
            ...mapGetters( {
                userIsAuth: 'user/isAuth',
                userDetail: 'user/wallet/getDetail'
            } ),
            keyword: {
                get( ) {
                    return this.$store.state.searchState.current.keyword;
                },
                set( value ) {
                    this.updateSearch( { keyword : value } );
                }
            }
        },
        watch: {
            menuExpanded( val ) {
                documentUtilsToggleBodyScroll( val );
            },
            userMenuExpanded( val ) {
                documentUtilsToggleBodyScroll( val );
            }
        },
        methods: {
            ...mapActions( {
                updateSearch: 'searchState/update'
            } ),
            goToLogin() {
                this.$router.push( { name: 'auth.login', params: { method: 'redirect' } } );
            },
            onFocusSearchDo() {
                this.isSearchFocused = true;
            },
            onBlurSearchDo() {
                this.isSearchFocused = false;
            }
        }
    };
</script>
<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-header {
        height: $header-height;
        
        .gst-header__logo {
            svg {
                @include mobile-only {
                    max-width: 158px;
                }
            }
        }

        .gst-header__search-keyword-with-autocomplete {
            .v-text-field--solo > .v-input__control {
                border-radius: border-radius( 'm' ) !important;
                min-height: 40px;
            }

            .v-text-field--solo > .v-input__control > .v-input__slot {
                background-color: theme-color( 'quinary' );
            }
        }
    }
</style>
