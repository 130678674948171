/** @format */

/**
 * Queue-it JavaScript implementation for when
 * we have KnownUser server-side SDK implemented
 * and are looking to add protection for in-page AJAX
 */
export default {
    init: () => {
        const scriptClient = document.createElement('script');
        scriptClient.setAttribute('src', '//static.queue-it.net/script/queueclient.min.js');
        document.head.appendChild(scriptClient);

        const scriptIntercept = document.createElement('script');
        scriptIntercept.setAttribute('data-queueit-intercept-domain', location.origin);
        scriptIntercept.setAttribute('data-queueit-intercept', 'true');
        scriptIntercept.setAttribute('data-queueit-spa', 'true');
        scriptIntercept.setAttribute('data-queueit-c', 'gsteam');
        scriptIntercept.setAttribute(
            'src',
            '//static.queue-it.net/script/queueconfigloader.min.js'
        );
        document.head.appendChild(scriptIntercept);
    },
};
