import { render, staticRenderFns } from "./NotificationTerms.vue?vue&type=template&id=5bde217a&scoped=true&"
import script from "./NotificationTerms.vue?vue&type=script&lang=js&"
export * from "./NotificationTerms.vue?vue&type=script&lang=js&"
import style0 from "./NotificationTerms.vue?vue&type=style&index=0&id=5bde217a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bde217a",
  null
  
)

export default component.exports